import React from "react";
import styled from "styled-components";
import logo from "../../../assets/svg/nowsaz-logo.svg";
import Socials from "../../Socials";

const Header = () => {
  return (
    <StyledHeader>
      <div className="content">
        <img src={logo} />
        <Socials />
      </div>
    </StyledHeader>
  );
};

export default Header;

const StyledHeader = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  height: auto;
  padding: 4px 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 95rem;
  }
`;
