import React, { useCallback, useState } from "react";
import styled from "styled-components";
import SettingsSvg from "./settings.svg";
import CubeSvg from "./cube.svg";
import { useSearchParams } from "react-router-dom";
import arrowRight from "../../assets/svg/arrow-right.svg";
import SidebarItems from "./sidebarItems";
import Settings from "./settings";

const SideBar = ({ open }: { open: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSetting, setIsSetting] = useState<boolean>(false);

  const handleIsSetting = useCallback(() => {
    setIsSetting(!isSetting);
  }, [isSetting]);

  const handleArrow = useCallback(() => {
    if (isSetting) {
      setIsSetting(false);
    } else {
      searchParams.delete("sidebar");
      setSearchParams(searchParams);
    }
  }, [searchParams, isSetting]);
  return open ? (
    <StyledSidebar open={open}>
      <SideOptions>
        <CubeSvg
          onClick={handleIsSetting}
          fill={!isSetting ? "#FFBF0F" : undefined}
          className={!isSetting ? "active" : ""}
        />
        <SettingsSvg
          onClick={handleIsSetting}
          fill={isSetting ? "#FFBF0F" : undefined}
          className={isSetting ? "active" : ""}
        />
      </SideOptions>
      <MainSection>
        <div className="title">
          <h3>{isSetting ? "تنظیمات" : "انتخاب محصول"}</h3>
          <img src={arrowRight} onClick={handleArrow} />
        </div>
        {isSetting ? <Settings /> : <SidebarItems />}
      </MainSection>
    </StyledSidebar>
  ) : (
    <></>
  );
};

export default SideBar;

const SideOptions = styled.div`
  width: auto;
  padding: 8px;
  border-left: 1px solid ${(p) => p.theme.colors.primaryDark};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  svg {
    cursor: pointer;
    padding: 4px;
    border-radius: 10px;
    &.active {
      background-color: #9cc3b5;
    }
  }
  @media (max-width: 450px) {
    flex-direction: row;
    padding: 8px 0px;
    width: 100%;
    border-left: 0px solid ${(p) => p.theme.colors.primaryDark};
    border-bottom: 1px solid ${(p) => p.theme.colors.primaryDark};
    svg {
      cursor: pointer;
      padding: 4px;
      border-radius: 10px;
      &.active {
        background-color: transparent;
      }
    }
  }
`;

const StyledSidebar = styled.div<{ open: boolean }>`
  display: flex;
  width: 350px;
  height: 100dvh;
  position: fixed;
  bottom: 0;
  right: ${({ open }) => (open ? 0 : "-350px")};
  background-color: #ffffff70;
  z-index: 9;
  backdrop-filter: blur(24px);

  animation: opening 0.4s linear;
  @media (max-width: 450px) {
    flex-direction: column;
    background-color: #ffffff;
    height: 60dvh;
    width: 100%;
    right: 0;
    bottom: ${({ open }) => (open ? 0 : "-60dvh")};
    animation: openingMobile 0.2s linear;
  }

  @keyframes opening {
    from {
      right: -350px;
    }
    to {
      right: 0;
    }
  }
  @keyframes openingMobile {
    from {
      bottom: -60dvh;
    }
    to {
      bottom: 0;
    }
  }
`;

const MainSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 60px;
  padding: 16px;
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    img {
      cursor: pointer;
    }
  }
  @media (max-width: 450px) {
    margin-top: 0px;
    width: auto;
    height: 100%;
    .title {
      img {
        display: none;
      }
    }
  }
`;
