import React from "react";
import styled from "styled-components";
import { locationDetail } from "../../../constants/mock.data";
import ImageListItem from "./image-list-item";
import { useSearchParams } from "react-router-dom";

const ImageList = ({ sideBarOpen }: { sideBarOpen: boolean }) => {
  const [searchParam] = useSearchParams();

  return (
    <Styled sideBarOpen={sideBarOpen}>
      {locationDetail.places.map((item) => (
        <ImageListItem
          key={item.id}
          sideBarOpen={sideBarOpen}
          placeId={item.id}
          choosenImage={
            Number(searchParam.get("" + item.id)) ?? item.options[0].id
          }
          maskImage={item.maskImage}
        />
      ))}
    </Styled>
  );
};

export default ImageList;

const Styled = styled.div<{ sideBarOpen: boolean }>`
  flex: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100dvh;
  display: table;
  @media (max-width: 450px) {
    height: ${({ sideBarOpen }) => (sideBarOpen ? "40dvh" : "100dvh")};
  }
`;
