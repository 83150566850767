import React, { ReactNode } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home";
import LoactionDetailPage from "./pages/location-detail";

type InnerRoute = {
  path: string;
  element: ReactNode;
};

const routes: InnerRoute[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/location/:id",
    element: <LoactionDetailPage />,
  },
];

const CustomRouter = () => {
  return (
    <BrowserRouter>
      {
        <Routes>
          {routes.map((item) => (
            <Route path={item.path} element={item.element} />
          ))}
        </Routes>
      }
    </BrowserRouter>
  );
};

export default CustomRouter;
