import React from "react";
import styled from "styled-components";
import Typography from "../Typography";

import loader from "../../../assets/image/loader.png";

const Loading = () => {
  return (
    <StyledLoading>
      <img src={loader} />
      <Typography fontSize={18}>در حال بارگذاری...</Typography>
    </StyledLoading>
  );
};

export default Loading;

const StyledLoading = styled.div`
  width: 100vw;
  height: 100dvh;
  background: ${({ theme }) => theme.colors.primary};
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  p {
    color: white;
  }
  img {
    animation: spin 0.4s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
