import render1 from "../assets/Renders/Render-1.png";
import render2 from "../assets/Renders/Render-2.png";
import render3 from "../assets/Renders/Render-3.png";
import render4 from "../assets/Renders/Render-4.png";
import render5 from "../assets/Renders/Render-5.png";
import render6 from "../assets/Renders/Render-6.png";
import render7 from "../assets/Renders/Render-7.png";
import render8 from "../assets/Renders/Render-8.png";

import mask1 from "../assets/Renders/Image_Masks/1.png";
import mask2 from "../assets/Renders/Image_Masks/2.png";
import mask3 from "../assets/Renders/Image_Masks/3.png";
import mask4 from "../assets/Renders/Image_Masks/4.png";
import mask5 from "../assets/Renders/Image_Masks/5.png";

import thumbnail1 from "../assets/Thumbnails/Living Room Floor/Maple Medley.jpg";
import thumbnail2 from "../assets/Thumbnails/Living Room Floor/Herringbone Oak.jpg";
import thumbnail3 from "../assets/Thumbnails/Living Room Floor/Birch Blend.jpg";
import thumbnail4 from "../assets/Thumbnails/Living Room Floor/Pistachio Velvet.jpg";
import thumbnail5 from "../assets/Thumbnails/Living Room Floor/Chocolate Mirage.jpg";
import thumbnail6 from "../assets/Thumbnails/Living Room Floor/Golden Glow.jpg";
import thumbnail7 from "../assets/Thumbnails/Living Room Floor/Invisible Gold.jpg";
import thumbnail8 from "../assets/Thumbnails/Living Room Floor/Beton Art.jpg";

import kitchenTumbnail1 from "../assets/Thumbnails/Kitchen Floor/Grey Matt.jpg";
import kitchenTumbnail2 from "../assets/Thumbnails/Kitchen Floor/Onyx.jpg";
import kitchenTumbnail3 from "../assets/Thumbnails/Kitchen Floor/Smole.jpg";
import kitchenTumbnail4 from "../assets/Thumbnails/Kitchen Floor/Elegant Grey.jpg";
import kitchenTumbnail5 from "../assets/Thumbnails/Kitchen Floor/Black Oxide.jpg";
import kitchenTumbnail6 from "../assets/Thumbnails/Kitchen Floor/Blue Roma.jpg";
import kitchenTumbnail7 from "../assets/Thumbnails/Kitchen Floor/Saint Laurent.jpg";
import kitchenTumbnail8 from "../assets/Thumbnails/Kitchen Floor/Metalic Blue.jpg";

import wallThumbnail1 from "../assets/Thumbnails/Wall No.1/Soft Sandstone.jpg";
import wallThumbnail2 from "../assets/Thumbnails/Wall No.1/Olive Haven.jpg";
import wallThumbnail3 from "../assets/Thumbnails/Wall No.1/Soft Glacier.jpg";
import wallThumbnail4 from "../assets/Thumbnails/Wall No.1/Greenery Groove.jpg";
import wallThumbnail5 from "../assets/Thumbnails/Wall No.1/Vintage Elegance.jpg";
import wallThumbnail6 from "../assets/Thumbnails/Wall No.1/Royal Reverie.jpg";
import wallThumbnail7 from "../assets/Thumbnails/Wall No.1/Polaris.jpg";
import wallThumbnail8 from "../assets/Thumbnails/Wall No.1/Mystic Black.jpg";

import cabinetThumbnail1 from "../assets/Thumbnails/Cabinet/Toffee Delight.jpg";
import cabinetThumbnail2 from "../assets/Thumbnails/Cabinet/Cocoa Cascade.jpg";
import cabinetThumbnail3 from "../assets/Thumbnails/Cabinet/Hazelnut Haze.jpg";
import cabinetThumbnail4 from "../assets/Thumbnails/Cabinet/Forest Fern.jpg";
import cabinetThumbnail5 from "../assets/Thumbnails/Cabinet/Caramel Charm.jpg";
import cabinetThumbnail6 from "../assets/Thumbnails/Cabinet/Chestnut Cinnamon.jpg";
import cabinetThumbnail7 from "../assets/Thumbnails/Cabinet/Amber Glow.jpg";
import cabinetThumbnail8 from "../assets/Thumbnails/Cabinet/Azure Isle.jpg";

import bsThumbnail1 from "../assets/Thumbnails/Backspalsh/Caspian Charm.jpg";
import bsThumbnail2 from "../assets/Thumbnails/Backspalsh/Chic Chevron.jpg";
import bsThumbnail3 from "../assets/Thumbnails/Backspalsh/Blue Harmony.jpg";
import bsThumbnail4 from "../assets/Thumbnails/Backspalsh/Green Waves.jpg";
import bsThumbnail5 from "../assets/Thumbnails/Backspalsh/Refined Retro.jpg";
import bsThumbnail6 from "../assets/Thumbnails/Backspalsh/Oceanic Swirl.jpg";
import bsThumbnail7 from "../assets/Thumbnails/Backspalsh/Elegant Blue.jpg";
import bsThumbnail8 from "../assets/Thumbnails/Backspalsh/Miracle Green.jpg";

type category = {
  id: number;
  name: string;
};

export type cover = {
  top?: string;
  right?: string;
  bottom?: string;
};

export type PlaceOption = {
  id: number;
  name: string;
  related_category: category;
  image: string;
  thumbnail: string;
};

type Place = {
  id: number;
  maskImage: string;
  options: PlaceOption[];
  cover?: cover;
};

type LocationDetail = {
  id: number;
  background: string;
  places: Place[];
};

export const categories: category[] = [
  {
    id: 1,
    name: "پارکت",
  },
  {
    id: 2,
    name: "موکت",
  },
  {
    id: 3,
    name: "کاشی و سرامیک",
  },
  {
    id: 4,
    name: "رنگ",
  },
  {
    id: 5,
    name: "کاغذ دیواری",
  },
];

export const locationDetail: LocationDetail = {
  id: 1,
  background: render1,
  places: [
    {
      id: 1,
      maskImage: mask1,
      options: [
        {
          id: 1,
          name: "Maple Medley",
          related_category: categories[0],
          image: render1,
          thumbnail: thumbnail1,
        },
        {
          id: 2,
          name: "Herringbone Oak",
          related_category: categories[0],
          image: render2,
          thumbnail: thumbnail2,
        },
        {
          id: 3,
          name: "Birch Blend",
          related_category: categories[0],
          image: render3,
          thumbnail: thumbnail3,
        },
        {
          id: 4,
          name: "Pistachio Velvet",
          related_category: categories[1],
          image: render4,
          thumbnail: thumbnail4,
        },
        {
          id: 5,
          name: "Chocolate Mirage",
          related_category: categories[1],
          image: render5,
          thumbnail: thumbnail5,
        },
        {
          id: 6,
          name: "Golden Glow",
          related_category: categories[1],
          image: render6,
          thumbnail: thumbnail6,
        },
        {
          id: 7,
          name: "Invisible Gold",
          related_category: categories[2],
          image: render7,
          thumbnail: thumbnail7,
        },
        {
          id: 8,
          name: "Beton Art",
          related_category: categories[2],
          image: render8,
          thumbnail: thumbnail8,
        },
      ],
      cover: {
        right: "50%",
        bottom: "20px",
      },
    },
    {
      id: 2,
      maskImage: mask2,
      options: [
        {
          id: 1,
          name: "Grey Matt",
          related_category: categories[2],
          image: render1,
          thumbnail: kitchenTumbnail1,
        },
        {
          id: 2,
          name: "Onyx",
          related_category: categories[2],
          image: render2,
          thumbnail: kitchenTumbnail2,
        },
        {
          id: 3,
          name: "Smole",
          related_category: categories[2],
          image: render3,
          thumbnail: kitchenTumbnail3,
        },
        {
          id: 4,
          name: "Elegant Grey",
          related_category: categories[2],
          image: render4,
          thumbnail: kitchenTumbnail4,
        },
        {
          id: 5,
          name: "Black Oxide",
          related_category: categories[2],
          image: render5,
          thumbnail: kitchenTumbnail5,
        },
        {
          id: 6,
          name: "Blue Roma",
          related_category: categories[2],
          image: render6,
          thumbnail: kitchenTumbnail6,
        },
        {
          id: 7,
          name: "Saint Laurent",
          related_category: categories[2],
          image: render7,
          thumbnail: kitchenTumbnail7,
        },
        {
          id: 8,
          name: "Metalic Blue",
          related_category: categories[2],
          image: render8,
          thumbnail: kitchenTumbnail8,
        },
      ],
      cover: {
        right: "82%",
        bottom: "50px",
      },
    },
    {
      id: 3,
      maskImage: mask3,
      options: [
        {
          id: 1,
          name: "Soft Sandstone",
          related_category: categories[3],
          image: render1,
          thumbnail: wallThumbnail1,
        },
        {
          id: 2,
          name: "Olive Haven",
          related_category: categories[3],
          image: render2,
          thumbnail: wallThumbnail2,
        },
        {
          id: 3,
          name: "Soft Glacier",
          related_category: categories[3],
          image: render3,
          thumbnail: wallThumbnail3,
        },
        {
          id: 4,
          name: "Greenery Groove",
          related_category: categories[4],
          image: render4,
          thumbnail: wallThumbnail4,
        },
        {
          id: 5,
          name: "Vintage Elegance",
          related_category: categories[4],
          image: render5,
          thumbnail: wallThumbnail5,
        },
        {
          id: 6,
          name: "Royal Reverie",
          related_category: categories[4],
          image: render6,
          thumbnail: wallThumbnail6,
        },
        {
          id: 7,
          name: "Polaris",
          related_category: categories[2],
          image: render7,
          thumbnail: wallThumbnail7,
        },
        {
          id: 8,
          name: "Mystic Black",
          related_category: categories[2],
          image: render8,
          thumbnail: wallThumbnail8,
        },
      ],
      cover: {
        right: "27%",
        top: "42%",
      },
    },
    {
      id: 4,
      maskImage: mask4,
      options: [
        {
          id: 1,
          name: "Toffee Delight",
          related_category: categories[3],
          image: render1,
          thumbnail: cabinetThumbnail1,
        },
        {
          id: 2,
          name: "Cocoa Cascade",
          related_category: categories[3],
          image: render2,
          thumbnail: cabinetThumbnail2,
        },
        {
          id: 3,
          name: "Hazelnut Haze",
          related_category: categories[3],
          image: render3,
          thumbnail: cabinetThumbnail3,
        },
        {
          id: 4,
          name: "Forest Fern",
          related_category: categories[3],
          image: render4,
          thumbnail: cabinetThumbnail4,
        },
        {
          id: 5,
          name: "Caramel Charm",
          related_category: categories[3],
          image: render5,
          thumbnail: cabinetThumbnail5,
        },
        {
          id: 6,
          name: "Chestnut Cinnamon",
          related_category: categories[3],
          image: render6,
          thumbnail: cabinetThumbnail6,
        },
        {
          id: 7,
          name: "Amber Glow",
          related_category: categories[3],
          image: render7,
          thumbnail: cabinetThumbnail7,
        },
        {
          id: 8,
          name: "Azure Isle",
          related_category: categories[3],
          image: render8,
          thumbnail: cabinetThumbnail8,
        },
      ],
      cover: {
        right: "67%",
        top: "30%",
      },
    },
    {
      id: 5,
      maskImage: mask5,
      options: [
        {
          id: 1,
          name: "Caspian Charm",
          related_category: categories[2],
          image: render1,
          thumbnail: bsThumbnail1,
        },
        {
          id: 2,
          name: "Chic Chevron",
          related_category: categories[2],
          image: render2,
          thumbnail: bsThumbnail2,
        },
        {
          id: 3,
          name: "Blue Harmony",
          related_category: categories[2],
          image: render3,
          thumbnail: bsThumbnail3,
        },
        {
          id: 4,
          name: "Green Waves",
          related_category: categories[2],
          image: render4,
          thumbnail: bsThumbnail4,
        },
        {
          id: 5,
          name: "Refined Retro",
          related_category: categories[2],
          image: render5,
          thumbnail: bsThumbnail5,
        },
        {
          id: 6,
          name: "Oceanic Swirl",
          related_category: categories[2],
          image: render6,
          thumbnail: bsThumbnail6,
        },
        {
          id: 7,
          name: "Elegant Blue",
          related_category: categories[2],
          image: render7,
          thumbnail: bsThumbnail7,
        },
        {
          id: 8,
          name: "Miracle Green",
          related_category: categories[2],
          image: render8,
          thumbnail: bsThumbnail8,
        },
      ],
      cover: {
        right: "70%",
        top: "44%",
      },
    },
  ],
};
