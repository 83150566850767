import React, { useState } from "react";
import mailSvg from "../../assets/svg/mail.svg";
import instagramSvg from "../../assets/svg/instagram.svg";
import phoneSvg from "../../assets/svg/phone.svg";
import styled from "styled-components";
import logo from "../../assets/svg/nowsaz-logo.svg";
import Typography from "../shared/Typography";

type Tsocial_dataItem = {
  id: number;
  icon: string;
  title: string;
  name: string;
  action: () => void;
  handlerTitle: string;
};

const social_data: Tsocial_dataItem[] = [
  {
    id: 1,
    icon: mailSvg,
    name: "ایمیل",
    title: "nowsaaz.com@gmail.com",
    action: () => window.open("mailto:nowsaaz.com@gmail.com"),
    handlerTitle: "ایمیل دهید",
  },
  {
    id: 2,
    icon: instagramSvg,
    title: "@nowsaaz_com",
    name: "اینستاگرام",
    action: () => window.open("https://instagram.com/nowsaaz_com"),
    handlerTitle: "برو به اینستاگرام!",
  },
  {
    id: 3,
    icon: phoneSvg,
    title: "0912 080 3568",
    name: "تلفن",
    action: () => window.open("tel:+989120803568"),
    handlerTitle: "تماس بگیرید!",
  },
];

const Socials = () => {
  const [showSocial, setShowSocial] = useState<Tsocial_dataItem | null>(null);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  return (
    <>
      <StyledSocialContainer>
        {social_data.map((item) => (
          <img
            key={"social" + item.id}
            src={item.icon}
            alt={item.title}
            onClick={() => setShowSocial(item)}
          />
        ))}
      </StyledSocialContainer>
      {showSocial && (
        <SocialModal>
          <div
            className="closeLayer"
            onClick={() => {
              setIsCopied(false);
              setShowSocial(null);
            }}
          ></div>
          <div className="contentLayer">
            <img src={logo} className="logo-container" />
            <Typography>
              شما میتوانید از طریق {showSocial?.name} با ما در ارتباط باشید:
            </Typography>

            <div
              className="main-content"
              onClick={() => {
                navigator.clipboard
                  .writeText(showSocial.title)
                  .then(() => setIsCopied(true));
              }}
            >
              <p>{showSocial.title}</p>
              <img src={showSocial.icon} className="logo-container pad" />
            </div>
            <div className="buttons-container">
              <button onClick={showSocial.action} className="outlined">
                {showSocial.handlerTitle}
              </button>
              <button
                className={isCopied ? " copied" : "primary"}
                onClick={() => {
                  navigator.clipboard
                    .writeText(showSocial.title)
                    .then(() => setIsCopied(true));
                }}
              >
                {isCopied ? "کپی شد!" : "کپی کنید"}
              </button>
            </div>
          </div>
        </SocialModal>
      )}
    </>
  );
};

export default Socials;

const StyledSocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-direction: row-reverse;
  img {
    cursor: pointer;
  }
`;

const SocialModal = styled.div`
  width: 100vw;
  height: 100dvh;
  backdrop-filter: blur(8px);
  background-color: #00000030;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  .closeLayer {
    position: fixed;
    z-index: 99;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100dvh;
  }
  .contentLayer {
    animation: open 1s normal;
    background: white;
    padding: 32px;
    border-radius: 12px;
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    align-items: center;
    .logo-container {
      background-color: ${(theme) => theme.theme.colors.primary};
      aspect-ratio: 1;
      border-radius: 8px;
      &.pad {
        padding: 4px;
      }
    }
    .main-content {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      gap: 8px;
      padding: 8px;
      border-radius: 8px;
      background: #eeeeee;
      border: 1px solid #e3e3e3;

      cursor: pointer;
      p {
        direction: ltr;
      }
    }
    .buttons-container {
      width: 100%;
      display: flex;
      gap: 12px;
      button {
        width: 100%;
        padding: 10px 0px;
        border-radius: 12px;
        border: 2px solid;
        cursor: pointer;
        font-weight: bolder;
        &.copied {
          background-color: #e3e3e3;
          border: 2px solid #e3e3e3;
        }
        &.primary {
          color: white;
          background-color: ${(theme) => theme.theme.colors.primary};
          &:hover {
            scale: 1.1;
          }
        }
        &.outlined {
          color: ${(theme) => theme.theme.colors.primary};
          border-color: ${(theme) => theme.theme.colors.primary};
          background-color: white;
          &:hover {
            color: white;
            background-color: ${(theme) => theme.theme.colors.primary};
            scale: 1.1;
          }
        }
      }
    }
    @keyframes open {
      from {
        scale: 0;
      }
      to {
        scale: 1;
      }
    }
  }
`;
