import React from "react";

const CubeSvg = ({
  fill,
  onClick,
  className,
}: {
  fill?: string;
  onClick: () => void;
  className: string;
}) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6667 9.33333L16 4L5.33337 9.33333M26.6667 9.33333L16 14.6667M26.6667 9.33333V22.6667L16 28M5.33337 9.33333L16 14.6667M5.33337 9.33333V22.6667L16 28M16 14.6667V28"
        stroke={fill ?? "#00482F"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CubeSvg;
