import React, { useMemo } from "react";
import styled from "styled-components";
import { locationDetail } from "../../constants/mock.data";
import { useSearchParams } from "react-router-dom";
import { Option } from "./sidebarItems";
import placeholder from "../../assets/image/placeholder-image.png";

const Settings = () => {
  const [searchParams] = useSearchParams();

  const activeItem = useMemo(() => {
    return locationDetail.places
      .find((item) => item.id === Number(searchParams.get("sidebar")))
      ?.options?.find((item) =>
        searchParams.get(searchParams.get("sidebar")!)
          ? searchParams.get(searchParams.get("sidebar")!) === "" + item.id
          : item.id === 1
      );
  }, [searchParams]);

  return (
    <Styled>
      <div className="header">
        <Option isActive={false}>
          <img
            src={
              activeItem?.thumbnail?.length !== 0
                ? activeItem?.thumbnail
                : placeholder
            }
          />
          <div className="names">
            <p className="name">{activeItem?.name}</p>
            <p className="category">{activeItem?.related_category.name}</p>
          </div>
        </Option>
        <div className="main">
          <h4>تنظیماتی برای این آیتم در دسترس نیست!</h4>
        </div>
      </div>
    </Styled>
  );
};

export default Settings;
const Styled = styled.div`
  margin-top: 16px;
  .main {
    border-top: 2px solid ${({ theme }) => theme.colors.primaryDark};
    padding: 8px;
    h4 {
      text-align: center;
    }
  }
`;
