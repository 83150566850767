import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { locationDetail } from "../../../constants/mock.data";
import { useSearchParams } from "react-router-dom";
import questionSvg from "../../../assets/svg/question.svg";

const Covers = ({ sideBarOpen }: { sideBarOpen: boolean }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showInfo, setShowInfo] = useState(true);
  const firstRef = useRef(null);

  useEffect(() => {
    if (firstRef.current) {
      // @ts-ignore

      firstRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [firstRef.current]);
  return (
    <Styled sideBarOpen={sideBarOpen}>
      {showInfo && !sideBarOpen && (
        <Info>
          <div className="title">
            <img src={questionSvg} />
            <p>شروع</p>
          </div>
          <p className="desc">
            یک آیتم را انتخاب کرده و گزینه های مربوطه را مشاهده کنید.
          </p>
          <div className="btn">
            <p onClick={() => setShowInfo(false)}>متوجه شدم</p>
          </div>
        </Info>
      )}
      {!sideBarOpen &&
        locationDetail.places.map((item) => (
          <StyledCover
            ref={item.id === 2 ? firstRef : null}
            top={item.cover?.top}
            right={item.cover?.right}
            bottom={item.cover?.bottom}
            onClick={() => {
              searchParams.append("sidebar", "" + item.id);
              setSearchParams(searchParams);
            }}
          >
            <div className="circle"></div>
          </StyledCover>
        ))}
    </Styled>
  );
};

export default Covers;

const Info = styled.div`
  max-width: 220px;
  padding: 16px;
  border-radius: 12px;
  position: fixed;
  z-index: 9;
  top: 80px;
  right: 24px;
  background: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 5px 5px 10px 0px #00000011;
  .title {
    display: flex;
    gap: 4px;
    font-size: 14px;
  }
  .desc {
    color: #6b7280;
    font-size: 14px;
  }
  .btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    p {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 16px;
      cursor: pointer;
    }
  }
`;

const Styled = styled.div<{ sideBarOpen: boolean }>`
  position: absolute;
  flex: 1;
  display: table-cell;
  overflow: hidden;
  height: 100dvh;
  width: 100%;
  z-index: 7;
  top: 0;
  right: 0;
  @media (max-width: 450px) {
    height: ${({ sideBarOpen }) => (sideBarOpen ? "40dvh" : "100dvh")};
  }
`;

const StyledCover = styled.div<{
  top?: string;
  right?: string;
  bottom?: string;
}>`
  position: absolute;
  z-index: 8;
  width: 110px;
  height: 110px;
  cursor: pointer;

  ${({ top, right, bottom }) => {
    return css`
      top: ${top ?? "auto"};
      bottom: ${bottom ?? "auto"};
      right: ${right ?? "auto"};
    `;
  }}
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    .circle {
      scale: 1.5;
    }
  }

  .circle {
    width: 23px;
    height: 23px;
    border-radius: 27px;
    border: 4px solid #6c6c6c25;
    background-color: white;
    box-shadow: 4px 4px 9px 1px #00000039;
  }
`;
