import React from "react";
import styled from "styled-components";
import Button from "../../components/shared/button";
import Typography from "../../components/shared/Typography";
import plusSvg from "../../assets/svg/plus.svg";
import homeBg from "../../assets/image/homebg.png";
import { useNavigate } from "react-router-dom";
import Socials from "../../components/Socials";

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <StyledHome>
      <Layer1>
        <Button onClick={() => navigate("/location/1")}>
          <>
            <img src={plusSvg} />
            <Typography fontWeight={800} fontSize={24}>
              خودت بساز
            </Typography>
            <img src={plusSvg} />
          </>
        </Button>
        <Socials />
      </Layer1>
    </StyledHome>
  );
};

export default HomePage;

const StyledHome = styled.div`
  background-color: ${(p) => p.theme.colors.primary};
  height: 100dvh;
  width: 100vw;
`;

const Layer1 = styled.div`
  height: 100dvh;
  width: 100vw;
  background-image: url(${homeBg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
`;
