import React, { Suspense, useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../../components/shared/header";
import LocationDetailMainSection from "../../components/location-detail/main";
import SideBar from "../../components/sidebar";
import { useSearchParams } from "react-router-dom";
import Loading from "../../components/shared/Loading";

const LoactionDetailPage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("first_time_done")) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } else {
      setTimeout(() => {
        setLoading(false);
        localStorage.setItem("first_time_done", "true");
      }, 6000);
    }
  }, []);
  return (
    <Suspense fallback={<Loading />}>
      {loading && <Loading />}
      <Header />
      <Container>
        <Wrapper sideBarOpen={!!searchParams.get("sidebar")}>
          <Style sideBarOpen={!!searchParams.get("sidebar")}>
            <LocationDetailMainSection
              sideBarIsOpen={!!searchParams.get("sidebar")}
            />
          </Style>
        </Wrapper>
      </Container>
      <SideBar open={!!searchParams.get("sidebar")} />
    </Suspense>
  );
};

export default LoactionDetailPage;

const Container = styled.div`
  width: 100vw;
  height: 100dvh;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Wrapper = styled.div<{ sideBarOpen: boolean }>`
  height: 100dvh;
  /* min-width: 100vw; */
  /* overflow-x: scroll; */
  overflow-y: hidden;
  /* display: flex; */
  /* justify-content: center; */

  @media (max-width: 450px) {
    /* justify-content: start; */
    height: ${({ sideBarOpen }) => (sideBarOpen ? "40dvh" : "100dvh")};
  }
`;

const Style = styled.div<{ sideBarOpen: boolean }>`
  display: table;
  flex-direction: column;
  height: 100dvh;
  /* width: 100vw; */
  max-width: 95rem;
  overflow: scroll;
  position: relative;
  @media (max-width: 450px) {
    height: ${({ sideBarOpen }) => (sideBarOpen ? "40dvh" : "100dvh")};
  }
`;
