import React, { ReactNode } from "react";
import styled, { DefaultTheme, css } from "styled-components";

type ButtonVariants = "primary" | "secondary" | "ghost";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: ButtonVariants;
}

const Button = ({ children, variant = "primary", ...rest }: ButtonProps) => {
  return (
    <StyledButton variant={variant} {...rest}>
      {children}
    </StyledButton>
  );
};

const variantStyles = (theme: DefaultTheme, variant: ButtonVariants) =>
  ({
    primary: css`
      color: ${theme.colors.lightGray};
      background: ${theme.colors.secondary};
      border: 1px solid ${theme.colors.secondary};
      &:hover {
        background: ${theme.colors.secondary}90;
        border: 1px solid ${theme.colors.secondary}80;
        box-shadow: 0px 2px 2px #00000050;
      }
    `,
    secondary: css`
      color: ${theme.colors.black};
      border: 1px solid ${theme.colors.secondary};
    `,
    ghost: css`
      color: ${theme.colors.black};
    `,
  }[variant]);

const StyledButton = styled.button<{ variant: ButtonVariants }>`
  display: flex;
  gap: 8px;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  border-radius: 80px;
  cursor: pointer;
  outline: none;
  ${({ theme, variant }) => variantStyles(theme, variant)}
`;

export default Button;
