import React, { ReactNode } from "react";
import styled, { DefaultTheme, css } from "styled-components";

type TypographyVariants = "small";

const Typography = ({
  children,
  variant = "small",
  fontSize,
  fontWeight,
}: {
  children?: ReactNode;
  variant?: TypographyVariants;
  fontSize?: number;
  fontWeight?: number;
}) => {
  return (
    <StyledTypography
      variant={variant}
      fontSize={fontSize}
      fontWeight={fontWeight}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
const variantStyles = (theme: DefaultTheme, variant: TypographyVariants) =>
  ({
    small: css`
      font-size: ${theme.fontSizes.small};
    `,
  }[variant]);

const StyledTypography = styled.p<{
  variant: TypographyVariants;
  fontSize?: number;
  fontWeight?: number;
}>`
  line-height: 32px;

  ${({ theme, variant }) => variantStyles(theme, variant)}
  ${({ fontSize }) =>
    css`
      font-size: ${fontSize}px;
    `}
    ${({ fontWeight }) => css`
    font-weight: ${fontWeight};
  `}
`;
