import React from "react";
import styled from "styled-components";
import { locationDetail } from "../../../../constants/mock.data";

const ImageListItem = ({
  maskImage,
  choosenImage,
  placeId,
  sideBarOpen,
}: {
  maskImage: string;
  choosenImage: number;
  placeId: number;
  sideBarOpen: boolean;
}) => {
  return (
    <Styled sideBarOpen={sideBarOpen} maskImge={maskImage}>
      <img
        src={
          locationDetail.places
            .find((itm) => itm.id == placeId)
            ?.options?.find((itm) => itm.id == choosenImage)?.image
        }
      />
    </Styled>
  );
};

export default ImageListItem;

const Styled = styled.div<{ maskImge: string; sideBarOpen: boolean }>`
  height: 100dvh;
  width: 100%;
  flex: 1;
  -webkit-mask-image: url(${({ maskImge }) => maskImge});
  mask-image: url(${({ maskImge }) => maskImge});
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  display: table-cell;
  mask-size: 100% 100dvh;
  top: 0;
  right: 0;
  z-index: 1;
  position: absolute;
  @media (max-width: 450px) {
    height: ${({ sideBarOpen }) => (sideBarOpen ? "40dvh" : "100dvh")};
    mask-size: ${({ sideBarOpen }) =>
      sideBarOpen ? "100% 40dvh" : "100% 100dvh"};
  }
  img {
    width: 100%;
    height: 100dvh;
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: 450px) {
      height: ${({ sideBarOpen }) => (sideBarOpen ? "40dvh" : "100dvh")};
    }
  }
`;
