import { DefaultTheme } from "styled-components";

const theme: DefaultTheme = {
  colors: {
    primary: "#12825B",
    secondary: "#FFBF0F",
    black: "#101010",
    lightGray: "#F5F5F5",
    primaryDark: "#035438",
  },
  fonts: ["Peyda", "Roboto"],
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
};

export default theme;
