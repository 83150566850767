import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import {
  PlaceOption,
  categories,
  locationDetail,
} from "../../constants/mock.data";
import placeholder from "../../assets/image/placeholder-image.png";
import searchSvg from "../../assets/svg/search.svg";
type Sortings = "ALPABETHIC" | "ID" | "CREATED_AT";

const SORTINGS: {
  id: Sortings;
  name: string;
}[] = [
  {
    id: "ALPABETHIC",
    name: "بر اساس الفبا",
  },
  {
    id: "ID",
    name: "بر اساس شناسه",
  },
  {
    id: "CREATED_AT",
    name: "جدیدترین",
  },
];

const SidebarItems = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<number | "null">(
    "null"
  );
  const [sorting, setSorting] = useState<Sortings | "null">("null");

  const sortHandler = (a: PlaceOption, b: PlaceOption) => {
    if (sorting !== "null") {
      if (sorting === "ALPABETHIC") {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      } else if (sorting === "CREATED_AT") {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      } else if (sorting === "ID") {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
        return 0;
      }
    }
    return 0;
  };

  return (
    <>
      <StyledInput>
        <img src={searchSvg} />
        <input
          placeholder="جستجو"
          type="search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </StyledInput>
      <StyledFilterContainer>
        <select
          value={selectedCategory}
          onChange={(e) => {
            if (e.target.value !== "null") {
              setSelectedCategory(Number(e.target.value));
            } else {
              setSelectedCategory("null");
            }
          }}
        >
          <option value={"null"}>دسته بندی</option>
          {categories.map((item) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </select>
        <select
          value={sorting}
          onChange={(e) => {
            // @ts-ignore
            setSorting(e.target.value);
          }}
        >
          <option value={"null"}>مرتب سازی</option>
          {SORTINGS.map((item) => (
            <option value={item.id}>{item.name}</option>
          ))}
        </select>
      </StyledFilterContainer>
      <Style>
        {locationDetail.places
          .find((item) => item.id === Number(searchParams.get("sidebar")))
          ?.options.filter((itm) => {
            return (
              itm.name.toLowerCase().includes(search.toLowerCase()) ||
              itm.related_category.name
                .toLowerCase()
                .includes(search.toLowerCase())
            );
          })
          .filter((item) => {
            if (selectedCategory !== "null") {
              return item.related_category.id === selectedCategory;
            } else {
              return item;
            }
          })
          .sort(sortHandler)
          .map((item) => (
            <Option
              onClick={() => {
                searchParams.delete(searchParams.get("sidebar")!);
                searchParams.append(searchParams.get("sidebar")!, "" + item.id);
                setSearchParams(searchParams);
              }}
              isActive={
                searchParams.get(searchParams.get("sidebar")!)
                  ? searchParams.get(searchParams.get("sidebar")!) ===
                    "" + item.id
                  : item.id === 1
              }
            >
              <img
                src={
                  item.thumbnail?.length !== 0 ? item.thumbnail : placeholder
                }
              />
              <div className="names">
                <p className="name">{item.name}</p>
                <p className="category">{item.related_category.name}</p>
              </div>
            </Option>
          ))}
      </Style>
    </>
  );
};

export default SidebarItems;

const Style = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding-bottom: 100px;
  overflow-y: scroll;
`;

const StyledFilterContainer = styled.div`
  display: flex;
  gap: 4px;
  select {
    background: #ffffff40;
    outline: none;
    border: none;
    border: 1px solid ${({ theme }) => theme.colors.primaryDark};
    padding: 8px 12px;
    border-radius: 20px;
    height: 40px;
    width: 100%;
    color: ${({ theme }) => theme.colors.primaryDark};
    &::placeholder {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
`;

const StyledInput = styled.div`
  margin-top: 16px;
  background: #ffffff40;
  padding: 8px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primaryDark};
  gap: 4px;
  input {
    background: transparent;
    outline: none;
    border: none;
    width: 100%;
    color: ${({ theme }) => theme.colors.primaryDark};
    &::placeholder {
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }
  @media (max-width: 450px) {
    margin-top: 0;
  }
`;

export const Option = styled.div<{ isActive: boolean }>`
  display: flex;
  cursor: pointer;
  border-radius: 12px;
  padding: 12px;
  gap: 8px;
  &:hover {
    background-color: #03543820;
  }
  background-color: ${({ isActive }) =>
    isActive ? "#12825B25" : "transparent"};
  img {
    border-radius: 8px;
    width: 64px;
    height: 64px;
    object-fit: cover;
    background-color: #03543820;
  }

  .names {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    .name {
      font-size: 16px;
      color: #191e25;
    }
    .category {
      font-size: 14px;
      color: #00482f;
    }
  }
`;
