import React from "react";
import styled from "styled-components";
import { locationDetail } from "../../../constants/mock.data";
import ImageList from "../image-list";
import Covers from "../covers";
import { useSearchParams } from "react-router-dom";

const LocationDetailMainSection = ({
  sideBarIsOpen,
}: {
  sideBarIsOpen: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Styled
      onClick={() => {
        if (sideBarIsOpen) {
          searchParams.delete("sidebar");
          setSearchParams(searchParams);
        }
      }}
      sideBarIsOpen={sideBarIsOpen}
      backgroundImage={locationDetail.background}
    >
      <div className="justice">
        {locationDetail.places.map((item) => (
          <div>
            {item.options.map((item) => (
              <img src={item.image} />
            ))}
          </div>
        ))}
      </div>
      <img className="source" src={locationDetail.background} />
      <ImageList sideBarOpen={sideBarIsOpen} />
      <Covers sideBarOpen={sideBarIsOpen} />
    </Styled>
  );
};

export default LocationDetailMainSection;

const Styled = styled.div<{ backgroundImage: string; sideBarIsOpen: boolean }>`
  flex: 1;
  width: 100%;
  display: table-cell;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
  .justice {
    display: none;
  }
  .source {
    height: 100dvh;
    opacity: 1;
    width: auto;
    object-fit: cover;
    @media (max-width: 450px) {
      height: ${({ sideBarIsOpen }) => (sideBarIsOpen ? "40dvh" : "100dvh")};
    }
  }
`;
